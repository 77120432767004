<template>
  <div>
    <b-form-group
      class="ml-0"
      label="Opções de seleção"
      description="Uma por linha, aperte enter para começar a escrever a próxima opção"
    >
      <b-form-textarea v-model="choices" @input="handleChangeChoice" rows="5">
        <small>Permitir multiplas seleções?</small>
      </b-form-textarea>
    </b-form-group>

    <b-form-group class="ml-0 mt-2">
      <b-form-checkbox class="pl-0" v-model="question.multiple" trim>
        <small>Permitir multiplas seleções</small>
      </b-form-checkbox>
    </b-form-group>

    <section>
      <b-form-group class="ml-0 mt-2">
        <b-form-checkbox class="pl-0" v-model="question.helpTextShow" trim>
          <small>Mostrar texto para auxílio de preenchimento</small>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="ml-0 mt-2">
        <b-form-textarea
          placeholder="Comece a escrever o texto"
          v-model="question.helpText"
          trim
          v-if="question.helpTextShow"
        >
        </b-form-textarea>
      </b-form-group>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      choices: "",
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChangeChoice(value) {
      const questions = value.split(/\r?\n/);

      const listQuestions = questions.map((label) => {
        return {
          label,
          value: label.replace(" ", "_").substr(0, 10),
        };
      });

      this.question.options = listQuestions;

      this.$emit("save_question", this.question);
    },
  },
  watch: {
    question: {
      handler(question) {
        if (!question?.options) {
          return;
        }

        this.choices = question?.options
          .flatMap(({ label }) => [label])
          .join("\n");
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
