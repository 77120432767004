<template>
  <div>
    <b-form-group class="ml-0 mt-2" label="Placeholder">
      <b-form-input
        placeholder="Comece a escrever o texto"
        v-model="question.placeholder"
        trim
      >
      </b-form-input>
    </b-form-group>

    <section class="d-md-flex justify-content-between gap-3">
      <b-form-group
        class="ml-0 mt-2"
        label="Máscara do texto"
        description="Deixe em branco caso não precise de máscara"
      >
        <b-form-input
          placeholder="Comece a escrever o texto"
          v-model="question.mask"
          trim
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        class="ml-0 mt-2"
        label="Número máximo de caracteres"
        description="Deixe vazio para não limitar"
      >
        <b-form-input type="number" v-model="question.maxLength">
        </b-form-input>
      </b-form-group>
    </section>
    <b-form-group class="ml-0 mt-2">
      <b-form-checkbox class="pl-0" v-model="question.helpTextShow">
        <small>Mostrar texto para auxílio de preenchimento</small>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group class="ml-0 mt-2">
      <b-form-textarea
        placeholder="Comece a escrever o texto"
        v-model="question.helpText"
        trim
        v-if="question.helpTextShow"
      >
      </b-form-textarea>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>
