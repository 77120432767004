<template>
  <b-card v-if="inRegister" class="mb-3" border-variant="primary">
    <strong class="text-primary d-block mb-3">Adicionar pergunta</strong>
    <b-form-input
      class="mt-2"
      placeholder="Escreva a pergunta"
      autofocus
      v-model="newQuestion"
    />
    <div class="d-flex align-items-center justify-content-end mt-2">
      <b-button
        variant="light"
        class="mr-2"
        size="sm"
        @click="handleCancelRegister()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click="handleSaveNewQuestion"
        :disabled="newQuestion.length < 5"
      >
        Salvar
      </b-button>
    </div>
  </b-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    inRegister: {
      type: Boolean,
    },
  },
  data() {
    return {
      newQuestion: "",
      formId: "",
    };
  },
  methods: {
    ...mapActions(["add_form_questions"]),

    makeid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    handleCancelRegister() {
      this.$emit("cancel");
    },

    handleSaveNewQuestion() {
      this.add_form_questions({
        title: this.newQuestion,
        message: "",
        required: true,
        type: "text",
        form: this.formId,
        id: this.makeid(),
      }).then(({ status }) => {
        if (![200, 201].includes(status)) {
          return;
        }

        this.$emit("save");
      });
    },
  },
  mounted() {
    this.formId = this.$route.params.id;
  },
};
</script>
