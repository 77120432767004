<template>
  <div class="text-left">
    <strong class="d-block mb-3">
      Avaliação de 0 a 10
    </strong>
    <div class="d-flex align-items-start w-100 gap-2">
      <section v-for="choice in choices" :key="choice" class="text-center">
        <b-button class="nps-button" :variant="getClassChoice(choice)">
          {{ choice + 1 }}
        </b-button>
      </section>
    </div>
    <b-form-group class="ml-0  mt-4">
      <b-form-checkbox class="pl-0" v-model="question.helpTextShow">
        <small>Mostrar texto para auxílio de preenchimento</small>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group class="ml-0 mt-2">
      <b-form-textarea
        placeholder="Comece a escrever o texto"
        v-model="question.helpText"
        trim
        v-if="question.helpTextShow"
      >
      </b-form-textarea>
    </b-form-group>
    <b-form-group class="ml-0 mt-2" label="Placeholder">
      <b-form-input
        placeholder="Comece a escrever o texto"
        v-model="question.placeholder"
        trim
      >
      </b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      choices: Array.from(Array(10).keys()),
    };
  },

  methods: {
    getClassChoice(choice) {
      if (choice < 6) {
        return "danger";
      }
      if (choice >= 6 && choice <= 7) {
        return "warning";
      }
      return "success";
    },
  },
};
</script>

<style lang="scss">
.nps-button {
  display: block;
  width: 30px;
  height: 30px;
  cursor: default;
  padding: 0;

  &.danger {
    background: #ff0000;
  }
  &.neutral {
    background: #ff5b00;
  }
  &.success {
    background: #00ff55;
  }
}
</style>
