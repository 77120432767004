<template>
  <div>
    <Header />
    <div class="body d-md-flex justify-content-between content-wrapper px-3">
      <div class="list-questions w-100">
        <div
          class="head d-md-flex justify-content-between align-items-center mb-3"
        >
          <h5>
            Perguntas <small>({{ questions.length }})</small>
          </h5>

          <div class="d-flex">
            <b-button
              @click="handleOpenModalPreview()"
              class="mr-2 px-1 px-md-3"
              variant="light"
              :disabled="inRegister"
            >
              <b-icon icon="eye" /> <span>Pré visualização</span>
            </b-button>
            <b-button
              class="mb-0 btn-add"
              @click="handleAddQuestions()"
              :disabled="inRegister"
            >
              Adicionar pergunta
            </b-button>
          </div>
        </div>

        <div class="d-flex w-100 justify-content-between gap-4">
          <div class="w-100">
            <CardNewQuestion
              v-if="inRegister"
              :inRegister="inRegister"
              @cancel="handleCancelRegister"
              @save="handleSaveNewQuestion"
            />
            <empty-list
              v-if="questions.length === 0 && !inRegister"
              text="Não há perguntas cadastradas"
            />
            <Draggable
              :list="listQuestions"
              Draggable=".move"
              @update="handleDragEnd"
            >
              <div
                class="w-100"
                :id="question.id"
                v-for="(question, index) in questions"
                :key="index"
                v-disable="inRegister"
              >
                <b-card
                  @click.prevent="handleSelectQuestion(question)"
                  block
                  :border-variant="
                    selectedQuestion.id === question.id ? 'primary' : ''
                  "
                  :text-variant="
                    selectedQuestion.id === question.id ? 'primary' : ''
                  "
                  no-body
                  class="d-flex py-3 px-4 align-items-center flex-row mb-2 pointer justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <b-icon icon="list" class="mr-2 move" />
                    <span>{{ question.title }}</span>
                  </div>
                  <b-icon-trash
                    @click="handleDeleteQuestion(question.id)"
                    class="pointer icon-button"
                  />
                </b-card>
              </div>
            </Draggable>
          </div>

          <b-card v-if="!is_mobile" class="min-h-60" style="min-width:450px">
            <detail-question
              :inRegister="inRegister"
              :questions="questions"
              :selectedQuestion="selectedQuestion"
            />
          </b-card>
        </div>
      </div>
      <b-modal
        id="detail-questions"
        centered
        hide-footer
        :title="`Propriedades da pergunta: ${selectedQuestion.title}`"
      >
        <detail-question
          :inRegister="inRegister"
          :questions="questions"
          :selectedQuestion="selectedQuestion"
          @deleted="getEdit()"
        />
      </b-modal>
    </div>
    <b-modal
      id="preview-questionaire"
      size="xl"
      centered
      modal-class="m-0 min-h-100 w-100"
      content-class="modal-preview"
    >
      <div>
        <ModalPreview
          :formId="id"
          @submited_questionaire="handleCloseModalPreview()"
        />
      </div>
      <template #modal-footer="{ close }">
        <b-button variant="primary" @click="close()">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Draggable from "vuedraggable";
import ModalPreview from "./ModalPreview";
import CardNewQuestion from "./CardNewQuestion";
import Header from "./Header";
import DetailQuestion from "./DetailQuestion.vue";

export default {
  components: {
    Draggable,
    ModalPreview,
    CardNewQuestion,
    Header,
    DetailQuestion,
  },

  computed: {
    ...mapGetters(["is_mobile"]),
  },

  data() {
    return {
      listQuestions: null,
      selectedQuestion: {
        title: "",
      },
      oldSelectedQuestion: {},
      drag: false,
      isLoading: false,
      inRegister: false,
      id: null,
      questions: [],
    };
  },

  methods: {
    ...mapActions([
      "add_form",
      "get_form",
      "show_toast",
      "edit_form",
      "delete_form_questions",
    ]),
    handleOpenModalPreview() {
      this.$bvModal.show("preview-questionaire");
    },
    handleCloseModalPreview() {
      this.$bvModal.hide("preview-questionaire");
    },

    handleAddQuestions() {
      this.inRegister = true;
      this.selectedQuestion = {};
    },
    handleCancelRegister() {
      this.inRegister = false;
      if (this.questions.length > 0) {
        this.selectedQuestion = this.questions[0];
      }
    },
    handleSaveNewQuestion() {
      this.handleCancelRegister();
      this.getEdit();
    },

    handleDragEnd(e) {
      const current = e?.item.id;
      const previous = e?.item?.previousElementSibling?.id || null;

      this.selectedQuestion = this.questions.find(({ id }) => id === current);
      this.selectedQuestion.previous = previous;
    },

    handleSelectQuestion(question) {
      if (this.is_mobile) {
        this.$bvModal.show("detail-questions");
      }

      this.oldSelectedQuestion = this.selectedQuestion;
      if (this.selectedQuestion?.id === question?.id) {
        return;
      }
      this.selectedQuestion = {};
      this.selectedQuestion = question;
    },

    getEdit() {
      this.get_form({ id: this.id }).then(({ data }) => {
        if (!data) {
          return;
        }
        const { name, description, questions } = data;
        this.name = name;
        this.description = description;
        this.questions = questions;
        if (this.questions.length > 0) {
          this.handleSelectQuestion(this.questions[0]);
        }
      });
    },
    handleDeleteQuestion(id) {
      this.delete_form_questions(id).then(() => {
        this.getEdit();
      });
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getEdit();
  },
};
</script>
