<template>
  <div>
    <section>
      <b-form-group class="ml-0 mt-2" label="Placeholder">
        <b-form-input
          placeholder="Comece a escrever o texto"
          v-model="question.placeholder"
        >
        </b-form-input>
      </b-form-group>
    </section>
    <section>
      <b-form-group
        class="ml-0 mt-2"
        label="Número máximo de caracteres"
        description="Deixe vazio para não limitar"
      >
        <b-form-input type="number" v-model="question.maxLength" trim>
        </b-form-input>
      </b-form-group>
      <b-form-group class="ml-0 mt-2">
        <b-form-checkbox class="pl-0" v-model="question.helpTextShow" trim>
          <small>Mostrar texto para auxílio de preenchimento</small>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="ml-0 mt-2">
        <b-form-textarea
          placeholder="Comece a escrever o texto"
          v-model="question.helpText"
          v-if="question.helpTextShow"
        >
        </b-form-textarea>
      </b-form-group>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>