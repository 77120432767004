<template>
  <div class="detail-questions " v-disable="inRegister">
    <strong v-if="questions.length === 0">
      Adicione uma pergunta para alterar suas propriedades
    </strong>
    <b-form v-else :form="form" @submit.prevent="handleSubmitConfigurations()" class="form-detail">
      <b-tabs small pills nav-class="nav-users">
        <b-tab title="Geral">
          <b-form-group label="Título" description="Instrução breve sobre qual aspecto é da pergunta. Ex: Benefícios.">
            <b-form-input disabled :value="form.tagline"> </b-form-input>
          </b-form-group>
          <b-form-group label="Pergunta"
            description="Pergunta objetiva para respostas sim e não. Ex: Você recebe vale alimentação?">
            <b-form-textarea rows="4" v-model="form.title" :class="$v.form.title.$error && 'is-invalid'">
              <b-form-invalid-feedback v-if="$v.form.title.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-textarea>
          </b-form-group>
          <b-form-group label="Descritivo da pergunta"
            description="Caso precise dar mais instruções sobre o que é perguntado. Ex: Também engloba vale alimentação.">
            {{ $v.description }}
            <b-form-textarea v-model="form.description"
              :class="$v.form.description.$error && 'is-invalid'"></b-form-textarea>
            <b-form-invalid-feedback v-if="$v.form.description.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="ml-0">
            <b-form-checkbox class="pl-0" checked disabled v-model="form.required">
              <small> Campo obrigatório</small>
            </b-form-checkbox>
          </b-form-group>
        </b-tab>
        <b-tab title="Propriedades do campo">
          <b-form-group label="Tipo">
            <b-form-select disabled :value="form.type">
              <option value="multiplechoice">Multipla escolha</option>
              <option value="text">Texto curto</option>
              <option value="longtext">Texto longo</option>
              <option value="multiplepicturechoice">Pontuação</option>
            </b-form-select>
          </b-form-group>
          <div class="inputs" v-switch="form.type">
            <ShortText v-case="'text'" :question.sync="form" />
            <LongText v-case="'longtext'" :question.sync="form" />
            <MultipleChoice v-case="'multiplechoice'" :question.sync="form" />
            <option-scale v-case="'multiplepicturechoice'" @save_question="handleSaveQuestion" :question="form" />
          </div>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-between w-100 gap-2">
        <div class="d-flex">
          <b-button :disabled="isLoading" :loading="isLoading" variant="primary" size="sm" type="submit">
            Salvar configurações
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ShortText from "./TypeInputs/ShortText";
import LongText from "./TypeInputs/LongText";
import MultipleChoice from "./TypeInputs/MultipleChoice";
import _ from "lodash";
import { mapActions } from "vuex";
import OptionScale from "./TypeInputs/OptionScale.vue";

export default {
  mixins: [validationMixin],
  components: {
    ShortText,
    LongText,
    MultipleChoice,
    OptionScale,
  },
  props: {
    inRegister: Boolean,
    questions: Array,
    selectedQuestion: Object,
  },
  data() {
    return {
      isLoading: false,
      id: null,
      form: {
        title: null,
        description: null,
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
      description: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["edit_form_questions", "show_toast"]),

    getRattingOptions() {
      const options = Array.from(Array(11).keys()).map((value) => ({
        value,
        label: value,
      }));

      return {
        options,
        multiple: false,
        inline: true,
        required: true,
      };
    },

    editformQuestion() {
      if (!this.id) {
        return;
      }

      let payload = {
        ...this.form,
        questionId: this.form.id,
        form: this.id,
      };

      if (payload.type === "multiplepicturechoice") {
        payload = { ...payload, ...this.getRattingOptions() };
      }

      delete payload.jump;

      this.edit_form_questions(payload)
        .then(() => {
          this.isLoading = false;
          this.show_toast({
            message: "Alterações salvas",
            type: "success",
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.show_toast({
            message: "Não foi possível salvar",
            type: "error",
          });
        });
    },

    handleChangeType() {
      this.form.options = [];
    },

    handleSubmitConfigurations() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.isLoading = true;
        this.editformQuestion();
      }
    },

    handleSaveQuestion: _.debounce(function (form) {
      this.form = { ...this.form, ...form };
      this.editformQuestion();
    }, 1000),
  },
  mounted() {
    this.id = this.$route.params.id;
  },

  watch: {
    selectedQuestion: {
      handler(question) {
        if (question) {
          this.form = question;
          this.form.tagline = "Em uma escala de 0 a 10";
          this.form.type = "multiplepicturechoice";
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.detail-questions {
  width: 400px;

  @media (max-width: $break-md) {
    width: 100%;
  }
}
</style>
